<template>
<div class="card border shadow-sm rounded-3 p-2 p-sm-4 h-100"
 :class="{'border-success shadow': plan.is_popular}">
    <div class="card-header p-0">
        <div class="d-flex justify-content-between align-items-center p-3 bg-light rounded-2">
            <div>
                <h5 class="mb-2">{{plan.title}}</h5>
                <p class="text-muted mb-0">{{plan.name}}</p>
            </div>
            <div>
                <h4 class="text-success mb-0 plan-price" >${{plan.price}}</h4>
                <p class="small mb-0">/ per {{plan.billing_period}}</p>
            </div>
        </div>
    </div>
    <p :class="{'text-primary':plan.is_popular}" class="text-center mt-3 mb-0">{{plan.description}}</p>
    <div class="position-relative my-3 text-center">
        <hr>
        <p class="small position-absolute top-50 start-50 translate-middle bg-body px-3">plans includes</p>
    </div>
    <!-- Card Body -->
    <div class="card-body pt-0">
        <ul class="list-unstyled mt-2 mb-0">
            <li v-for="(feature, fIndex) in plan.features" :key="fIndex"
             class="h6 fw-light" :class="{'mb-3' : fIndex != plan.features.length-1}"><i class="bi bi-patch-check-fill text-success me-2"></i> <span v-html="feature"></span></li>
        </ul>
    </div>
    <!-- Card Footer -->
    <div v-if="!hideBtn" class="card-footer text-center d-grid pb-2">
        <a v-if="plan.type == 'device'" href="/device-plan" :class="plan.is_popular ? 'btn-primary': 'btn-outline-primary'" class="btn btn-lg mb-0">Get Started</a>
    </div>
</div>
</template>

<script>
export default {
    props:{
        plan:{
            required: true,
            type: Object
        },
        hideBtn:{
            type: Boolean,
            default: false
        },
    },
}
</script>

<style>

</style>