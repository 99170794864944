<template>
<div class="pb-5">
    <div v-if="plans.length" class="row">
        <div class="col-12">
            <div class="row g-4">
                <div class="col-12">
                    <h5 class="mb-0">Select Plans</h5>
                </div>
                <div v-for="(plan, pIndex) in plans" :key="pIndex" class="col-lg-6">
                    <plan-card :plan="plan" />
                </div>
            </div>
        </div>
    </div>
    <is-loading  v-else/> 
</div>
</template>

<script>
import PlanCard from '@/components/cards/PlanCard.vue'
import IsLoading from '@/components/IsLoading.vue'

export default {
    name: 'plans',
    components:{
        PlanCard,
        IsLoading
    },
    computed:{
        activeUser(){
            return this.$store.state.AppActiveUser
        },
        plans(){
            return this.$store.state.plans
        }
    },
    created(){
        this.$store.dispatch("fetchPlans")
    }
}
</script>

<style scoped>
</style>
